import { useTranslation } from 'react-i18next';
import ReactCookieConsent from 'react-cookie-consent';
import { motion, AnimatePresence } from 'framer-motion';

const CookieConsent = () => {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ 
          type: "spring",
          stiffness: 400,
          damping: 30,
          mass: 1,
          delay: 2
        }}
      >
        <ReactCookieConsent
          location="bottom"
          buttonText={t('cookies.accept')}
          declineButtonText={t('cookies.decline')}
          enableDeclineButton
          style={{
            background: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(8px)',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)',
            padding: '0.625rem',
            maxWidth: '100%',
            zIndex: 40,
          }}
          buttonStyle={{
            background: '#2563eb',
            color: 'white',
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.375rem 0.875rem',
            borderRadius: '0.375rem',
            border: '1px solid #2563eb',
            cursor: 'pointer',
            transition: 'all 150ms ease',
            minWidth: '70px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
          }}
          declineButtonStyle={{
            background: 'transparent',
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.375rem 0.875rem',
            borderRadius: '0.375rem',
            border: '1px solid #d1d5db',
            cursor: 'pointer',
            transition: 'all 150ms ease',
            minWidth: '70px',
            color: '#9ca3af',
          }}
          buttonWrapperClasses="flex flex-row gap-2 items-center justify-center w-full sm:w-auto mt-2 sm:mt-0 mx-auto"
          expires={150}
          containerClasses="!fixed !w-[calc(100%-2rem)] sm:!w-auto !max-w-md !mx-auto !left-4 !right-4 !bottom-4 !rounded-lg !bg-white dark:!bg-gray-800/70 dark:!border-gray-700/50 dark:!bg-opacity-70 !border-gray-200/60"
          contentClasses="flex flex-col items-center text-center justify-between w-full gap-1"
          overlayClasses="!bg-transparent"
          onAccept={() => {
            console.log("Cookies accepted");
          }}
          onDecline={() => {
            console.log("Cookies declined");
          }}
        >
          <div className="text-gray-600 dark:text-gray-300 text-sm leading-relaxed px-1">
            {t('cookies.message')}{' '}
            <a
              href="/privacy"
              className="text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 underline transition-colors duration-200"
            >
              {t('cookies.learnMore')}
            </a>
          </div>
        </ReactCookieConsent>
      </motion.div>
    </AnimatePresence>
  );
};

export default CookieConsent; 