import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex flex-col items-center text-center">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 1
            }}
            className="relative"
          >
            {/* Animated 404 Text */}
            <motion.div
              animate={{ 
                rotate: [0, -5, 5, -5, 0],
                y: [0, -10, 0]
              }}
              transition={{ 
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="text-8xl sm:text-9xl font-black text-primary-600 dark:text-primary-400"
            >
              404
            </motion.div>

            {/* Decorative elements */}
            <motion.div
              animate={{ 
                rotate: 360,
                scale: [1, 1.2, 1]
              }}
              transition={{ 
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="absolute -top-8 -right-8 w-16 h-16 text-yellow-500 dark:text-yellow-300 opacity-75"
            >
              ✨
            </motion.div>
            <motion.div
              animate={{ 
                rotate: -360,
                scale: [1, 1.1, 1]
              }}
              transition={{ 
                duration: 4,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="absolute -bottom-4 -left-8 w-16 h-16 text-blue-500 dark:text-blue-300 opacity-75"
            >
              💫
            </motion.div>
          </motion.div>

          <div className="mt-8">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white tracking-tight sm:text-5xl mb-4">
              Oops! Page not found
            </h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mt-4 text-lg text-gray-500 dark:text-gray-400 max-w-lg mx-auto"
            >
              Looks like this page took a vacation! Don't worry, our best detectives are on the case. 
              In the meantime, why not head back home?
            </motion.p>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="mt-10"
            >
              <Link
                to="/"
                className="inline-flex items-center px-6 py-3 rounded-full bg-primary-600 text-white text-lg font-semibold shadow-lg hover:bg-primary-500 transform hover:scale-105 transition-all duration-200"
              >
                <span className="mr-2">👋</span>
                Take me home
              </Link>
            </motion.div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotFound; 