import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'next-themes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CookieConsent from './components/CookieConsent';
import ReactGA from 'react-ga4';


// Page imports
import Layout from './components/Layout';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import NotFound from './pages/NotFound';
import About from './pages/About';
import Jobs from './pages/Jobs';
import Training from './pages/Training';
import ApiStatus from './pages/ApiStatus';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Security from './pages/Security';
import Contact from './pages/Contact';
import Demo from './pages/Demo';
import Success from './pages/Success';
import MessageSent from './pages/MessageSent';


// Initialize GA4 with your measurement ID
ReactGA.initialize('G-B59L6P4X6M');

// Export the App component
export function App() {
  const { i18n } = useTranslation();

  return (
    <Router>
      <HelmetProvider>
        <ThemeProvider attribute="class">
          <Layout>
            <Routes>
              {/* English (default) routes */}
              <Route path="/" element={<Home />} exact />
              <Route path="/about" element={<About />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/training" element={<Training />} />
              <Route path="/api-status" element={<ApiStatus />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/security" element={<Security />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/demo" element={<Demo />} />
              <Route path="/blog" element={<Blog />} exact />
              <Route path="/404" element={<NotFound />} />
              <Route path="/:slug" element={<BlogPost />} />
              <Route path="/verifypdf-free-document-check-sent" element={<Success />} />
              <Route path="/message-sent" element={<MessageSent />} />

              {/* Localized home pages */}
              <Route path="/es" element={<Home />} />
              <Route path="/fr" element={<Home />} />
              <Route path="/de" element={<Home />} />
              <Route path="/nl" element={<Home />} />

              {/* 404 catch-all */}
              <Route path="*" element={<NotFound />} />              
            </Routes>
            <CookieConsent />
          </Layout>
        </ThemeProvider>
      </HelmetProvider>
    </Router>
  );
}

// Default export for App
export default App;
