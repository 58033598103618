/**
 * Core configuration constants for VerifyPDF's pricing and FAQ sections
 * 
 * This file contains:
 * - Pricing tier definitions (features, prices, descriptions)
 * - Frequently Asked Questions with answers
 * 
 * Note: Content is now managed through i18n translations
 */

export const tiers = [
  {
    id: 'tier-basic',
    href: 'https://secure.verifypdf.com/signup',
    featured: false,
    translationKey: 'basic'
  },
  {
    id: 'tier-professional',
    href: 'https://secure.verifypdf.com/signup',
    featured: true,
    translationKey: 'professional'
  },
  {
    id: 'tier-corporate',
    href: '/contact',
    featured: false,
    translationKey: 'corporate'
  }
];

// FAQs are now loaded directly from translations
export const getFAQs = (t) => {
  return t('faq.items', { returnObjects: true });
};

// Helper to get pricing tier data
export const getTierData = (tier, t) => {
  try {
    const data = t(`pricing.tiers.${tier.translationKey}`, { returnObjects: true });
    if (!data) {
      console.warn(`No translation data found for tier: ${tier.translationKey}`);
      return {
        ...tier,
        name: '',
        priceMonthly: '',
        description: '',
        features: []
      };
    }
    return {
      ...tier,
      name: data.name || '',
      priceMonthly: data.price || '',
      description: data.description || '',
      features: Array.isArray(data.features) ? data.features : []
    };
  } catch (error) {
    console.error(`Error loading tier data for ${tier.translationKey}:`, error);
    return {
      ...tier,
      name: '',
      priceMonthly: '',
      description: '',
      features: []
    };
  }
}; 