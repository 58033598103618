import { motion } from 'framer-motion';
import PageTemplate from '../components/PageTemplate';
import { 
  ShieldCheckIcon,
  CurrencyDollarIcon,
  BuildingOfficeIcon,
  TruckIcon,
  HomeIcon,
  ScaleIcon
} from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const About = () => {
  const { t } = useTranslation('about');
  const [currentObjective, setCurrentObjective] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentObjective((prev) => (prev + 1) % objectives.length);
    }, 7000);

    return () => clearInterval(timer);
  }, []);

  const objectives = t('objectives.items', { returnObjects: true });

  return (
    <PageTemplate>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative pt-6 pb-12 px-4 sm:px-6 lg:px-8 overflow-hidden"
        >
          <div className="relative z-10 max-w-7xl mx-auto text-center">
            <motion.h1 
              className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 mt-4 sm:mt-8 px-4 md:px-12 lg:px-24"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {t('hero.title')}
              {' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700 animate-gradient">
                {t('hero.highlight')} 
              </span>
            </motion.h1>

            <motion.p
              className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto px-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              {t('hero.description')}
            </motion.p>
          </div>
        </motion.section>

        {/* Objectives Section */}
        <section className="relative py-16 bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <motion.h2 
              className="mt-2 font-['Domine'] text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl text-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              {t('objectives.title')}
            </motion.h2>

            <div className="relative max-w-4xl mx-auto">
              <motion.div 
                className="overflow-hidden rounded-2xl"
                initial={false}
              >
                <motion.div
                  className="flex"
                  animate={{ x: `${-currentObjective * 100}%` }}
                  transition={{ 
                    type: "spring", 
                    stiffness: 200, 
                    damping: 35, 
                    duration: 1
                  }}
                >
                  {objectives.map((objective, index) => (
                    <motion.div
                      key={objective.number}
                      className="min-w-full p-8"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: currentObjective === index ? 1 : 0.3 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="bg-white dark:bg-gray-800 rounded-2xl p-8 shadow-xl border border-gray-100 dark:border-gray-700">
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center gap-4 mb-2">
                            <span className="text-5xl font-black text-primary-500/20 dark:text-primary-400/20">
                              {objective.number}
                            </span>
                            <h4 className="font-['Domine'] text-2xl font-bold text-gray-900 dark:text-white">
                              {objective.title}
                            </h4>
                          </div>
                          <p className="font-['Nunito'] text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                            {objective.description}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>



        {/* Use Cases Section */}
        <section className="relative py-16 bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center mb-12">
              <motion.h2 
                className="mt-2 font-['Domine'] text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl text-center"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                {t('useCases.title')}
              </motion.h2>
            </div>

            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              {[
                {
                  key: 'lending',
                  icon: CurrencyDollarIcon,
                  gradient: "from-blue-500 to-cyan-500"
                },
                {
                  key: 'insurance',
                  icon: ShieldCheckIcon,
                  gradient: "from-purple-500 to-pink-500"
                },
                {
                  key: 'employers',
                  icon: BuildingOfficeIcon,
                  gradient: "from-orange-500 to-yellow-500"
                },
                {
                  key: 'autoLeasing',
                  icon: TruckIcon,
                  gradient: "from-green-500 to-emerald-500"
                },
                {
                  key: 'realEstate',
                  icon: HomeIcon,
                  gradient: "from-red-500 to-orange-500"
                },
                {
                  key: 'legal',
                  icon: ScaleIcon,
                  gradient: "from-indigo-500 to-purple-500"
                }
              ].map((useCase, index) => {
                const useCases = t('useCases.items', { returnObjects: true });
                const translation = useCases[index];
                
                return (
                  <motion.div
                    key={index}
                    className="group relative"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ scale: 1.02 }}
                  >
                    <div className="absolute inset-0 rounded-3xl bg-gradient-to-br opacity-10 dark:opacity-20 group-hover:opacity-15 dark:group-hover:opacity-30 transition-opacity duration-300"
                      style={{ backgroundImage: `linear-gradient(to bottom right, var(--tw-gradient-stops))` }}
                    />
                    <div className="relative h-full p-6 rounded-3xl bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm border border-gray-200 dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-600 transition-all duration-300">
                      <div className="h-full flex flex-col items-center justify-start text-center p-4">
                        <motion.div 
                          className={`rounded-full p-4 bg-gradient-to-br ${useCase.gradient} mb-6 mt-4`}
                          whileHover={{ rotate: 360 }}
                          transition={{ duration: 0.8 }}
                        >
                          <useCase.icon 
                            className="h-8 w-8 text-white"
                            aria-hidden="true" 
                          />
                        </motion.div>
                        
                        <h3 className="font-['Domine'] text-2xl font-bold text-gray-900 dark:text-white mb-4">
                          {translation.title}
                        </h3>
                        
                        <p className="font-['Nunito'] text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                          {translation.description}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </motion.div>
          </div>
        </section>

      </div>
    </PageTemplate>
  );
};

export default About; 