import { motion } from 'framer-motion';
import PageTemplate from '../components/PageTemplate';
import { Link } from 'react-router-dom';

const Training = () => {
  return (
    <PageTemplate
      title="Coming soon"
      description="We are planning to launch training materials in Q1 2025"
    >
      <div className="flex flex-col items-center justify-center min-h-[10vh] bg-white dark:bg-gray-900 px-4 py-8 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-8"
        >
          <Link
            to="/"
            className="inline-flex items-center px-6 py-3 rounded-full bg-primary-600 text-white text-lg font-semibold shadow-lg hover:bg-primary-500 transform hover:scale-105 transition-all duration-200 no-underline"
          >
            <span className="mr-2">👋</span>
            Take me home
          </Link>
        </motion.div>
      </div>
    </PageTemplate>
  );
};

export default Training; 