const RESERVED_PATHS = ['articles', 'about', 'contact', 'pricing', 'terms', 'privacy'];

const postRegistry = new Map();

export const getPostData = async (slug) => {
  try {
    if (RESERVED_PATHS.includes(slug)) {
      return null;
    }

    // First get the metadata from index.json
    const response = await fetch('/content/blog/index.json');
    const posts = await response.json();
    const post = posts.find(p => p.slug === slug);
    
    if (!post) {
      return null;
    }

    // Then fetch the content
    const contentResponse = await fetch(`/content/blog/${slug}.md`);
    if (!contentResponse.ok) {
      throw new Error('Failed to fetch post content');
    }
    
    const markdown = await contentResponse.text();
    
    // Simple frontmatter parsing
    const [, frontmatter, content] = markdown.split('---');
    const metadata = {};
    frontmatter.split('\n').forEach(line => {
      const [key, ...values] = line.split(':');
      if (key && values.length) {
        const value = values.join(':').trim().replace(/^"(.*)"$/, '$1');
        if (key.trim() === 'tags') {
          // Parse tags as array
          metadata.tags = value.split(',').map(tag => tag.trim());
        } else {
          metadata[key.trim()] = value;
        }
      }
    });

    return {
      ...post,
      ...metadata,
      content: content.trim()
    };
  } catch (error) {
    console.error('Error in getPostData:', error);
    return null;
  }
};

export const getAllPosts = async () => {
  try {
    const response = await fetch('/content/blog/index.json');
    const posts = await response.json();
    return posts.sort((a, b) => new Date(b.date) - new Date(a.date));
  } catch (error) {
    console.error('Error loading posts:', error);
    return [];
  }
}; 