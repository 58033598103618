import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import CTA from '../components/CTA';
import confetti from 'canvas-confetti';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Success() {
  const { t } = useTranslation();

  useEffect(() => {
    // Single confetti burst
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      disableForReducedMotion: true
    });
  }, []);

  return (
    <>
      <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
        <Helmet>
          <title>{t('success.title')} - VerifyPDF</title>
          <meta name="description" content={t('success.description')} />
        </Helmet>

        {/* Success Section */}
        <section className="relative pt-40 pb-10 px-4 sm:px-6 lg:px-8 overflow-hidden isolate">
          {/* Base gradient */}
          <div className="absolute inset-0 -z-50" aria-hidden="true">
            <div className="absolute inset-0 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] dark:bg-[radial-gradient(45rem_50rem_at_top,theme(colors.primary.900),theme(colors.gray.900))] opacity-20" />
          </div>

          <div className="relative z-10 max-w-3xl mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center"
            >
              <motion.div 
                className="rounded-full bg-green-100 dark:bg-green-900/30 p-4 mb-8 relative overflow-hidden"
                initial={{ scale: 0 }}
                animate={{ scale: [0, 1.2, 1] }}
                transition={{ 
                  duration: 0.5,
                  times: [0, 0.6, 1]
                }}
              >
                <motion.div
                  animate={{ 
                    rotate: [0, 10, -10, 10, 0],
                    scale: [1, 1.1, 1]
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.5,
                    ease: "easeInOut"
                  }}
                >
                  <CheckCircleIcon className="h-24 w-24 text-green-600 dark:text-green-400" />
                </motion.div>
                
                {/* Reflection effect */}
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-30 dark:via-green-200"
                  initial={{ x: '-100%' }}
                  animate={{ x: '100%' }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatDelay: 1,
                    ease: "easeInOut"
                  }}
                />
              </motion.div>

              <h1 className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8">
                {t('success.heading')}
              </h1>

              <div className="bg-white/60 dark:bg-gray-800/60 backdrop-blur-sm rounded-2xl shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700 p-8 mb-8 w-full max-w-2xl">
                <p className="font-['Nunito'] text-xl text-gray-900 dark:text-white mb-6">
                  {t('success.message')}
                </p>
                
                <div className="bg-yellow-50 dark:bg-yellow-900/30 border border-yellow-200 dark:border-yellow-800 rounded-lg p-4">
                  <h2 className="font-['Domine'] text-lg font-semibold text-yellow-800 dark:text-yellow-200 mb-2">
                    {t('success.check.havenReceived')}
                  </h2>
                  <p className="font-['Nunito'] text-yellow-700 dark:text-yellow-300">
                    {t('success.check.checkSpam')}
                  </p>
                </div>
              </div>

              <Link
                to="/"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:hover:bg-primary-500 transition-colors duration-200"
              >
                {t('contact.success.backToHome')}
              </Link>
            </motion.div>
          </div>
        </section>

        {/* CTA Section */}
        <CTA />
      </div>
    </>
  );
}

export default Success; 