import React from 'react';
import { useTheme } from 'next-themes';

const SplashScreen = () => {
  const { theme } = useTheme();
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white dark:bg-gray-900">
      <div className="animate-pulse">
        <img 
          src={theme === 'dark' ? '/logo_white.png' : '/logo_black.png'}
          alt="Verify PDF Logo" 
          className="h-16 w-auto"
        />
      </div>
      <div className="mt-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    </div>
  );
};

export default SplashScreen; 