/**
 * CTA (Call To Action) Component
 * 
 * A prominent marketing section typically displayed on the homepage or key landing pages.
 * Features a headline about AI document verification, marketing copy, and action buttons
 * for starting a free trial or watching a demo. Includes an animated gradient background
 * and responsive layout that adapts from mobile to desktop views.
 * 
 * The component also integrates a RiskDisplay component and supports both light/dark modes
 * through tailwind classes.
 */
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import RiskDisplay from './RiskDisplay';
import { useTranslation } from 'react-i18next';

const CTA = () => {
  const { t } = useTranslation('cta');

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 py-16">
      <div className="relative isolate overflow-hidden bg-blue-50/80 dark:bg-gray-800 px-6 pt-16 shadow-xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:items-center lg:gap-x-20 lg:px-24 lg:pt-0">
        <svg
          viewBox="0 0 1024 1024"
          aria-hidden="true"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
        >
          <circle 
            r={512} 
            cx={512} 
            cy={512} 
            fill="url(#gradient-dark)"
            className="dark:hidden" 
            fillOpacity="0.7" 
          />
          <circle 
            r={512} 
            cx={512} 
            cy={512} 
            fill="url(#gradient-light)"
            className="hidden dark:block" 
            fillOpacity="0.7" 
          />
          <defs>
            <radialGradient id="gradient-dark">
              <stop stopColor="#3B82F6" />
              <stop offset={1} stopColor="#60A5FA" />
            </radialGradient>
            <radialGradient id="gradient-light">
              <stop stopColor="#0EA5E9" />
              <stop offset={1} stopColor="#38BDF8" />
            </radialGradient>
          </defs>
        </svg>
        
        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
          <h2 className="font-['Domine'] text-balance text-3xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
            {t('headline.prefix')}{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700 animate-gradient">
              {t('headline.highlight')}
            </span>
          </h2>
          <p className="font-['Nunito'] mt-6 text-pretty text-base leading-7 text-gray-600 dark:text-gray-300">
            {t('description')}
          </p>
          <div className="mt-10 mb-16 sm:mb-0 flex items-center justify-center gap-x-6 lg:justify-start">
            <motion.a
              href="https://secure.verifypdf.com/signup"
              className="rounded-md bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500 px-3.5 py-2.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {t('buttons.trial')}
            </motion.a>
            <motion.div whileHover={{ x: 5 }}>
              <Link 
                to="/demo" 
                className="text-sm/6 font-semibold text-gray-900 dark:text-white group"
              >
                {t('buttons.demo')} <span aria-hidden="true" className="inline-block transition-transform group-hover:translate-x-1">→</span>
              </Link>
            </motion.div>
          </div>
        </div>
        
        <RiskDisplay />
      </div>
    </div>
  );
};

export default CTA; 