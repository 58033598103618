/**
 * RiskDisplay Component
 * 
 * A dynamic component that alternates between displaying two states:
 * 1. Trusted Document State - Shows when a document has been verified as legitimate
 * 2. Fraud Risk State - Shows when a document has potential signs of manipulation
 * 
 * The component automatically toggles between these states every 8 seconds for demonstration purposes.
 * Each state includes animated indicators and a list of verification checks or warning signs.
 * 
 * Used in CTA component (which is used in the home page, landing page and blog post pages)
 */

import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const RiskDisplay = () => {
  const [showTrusted, setShowTrusted] = useState(true);
  const { t } = useTranslation('cta');

  useEffect(() => {
    const timer = setInterval(() => {
      setShowTrusted(prev => !prev);
    }, 8000);

    return () => clearInterval(timer);
  }, []);

  return (
    <motion.div 
      className="relative h-[450px] sm:h-[450px] lg:h-[400px] w-full lg:w-[57rem] max-w-none my-auto px-4 sm:px-6 lg:px-0"
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
    >
      <motion.div 
        className="absolute left-0 top-0 w-full h-full rounded-xl overflow-hidden"
      >
        <AnimatePresence mode="wait">
          {showTrusted ? (
            <motion.div 
              key="trusted"
              className="h-full flex items-start pt-4 pb-0 sm:pt-6 sm:pb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="space-y-3 sm:space-y-4 p-4 sm:p-6 w-full">
                <motion.div 
                  className="bg-green-100 dark:bg-green-900/30 p-3 sm:p-4 rounded-xl border border-green-200 dark:border-green-800"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <div className="flex items-center space-x-3 sm:space-x-4">
                    <motion.div
                      className="w-3 h-3 sm:w-3.5 sm:h-3.5 rounded-full bg-green-500 aspect-square flex-shrink-0"
                      animate={{ 
                        scale: [1, 1.4, 1],
                        boxShadow: [
                          '0 0 0 0 rgba(34, 197, 94, 0.4)',
                          '0 0 0 10px rgba(34, 197, 94, 0)',
                          '0 0 0 0 rgba(34, 197, 94, 0)'
                        ]
                      }}
                      transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <div>
                      <motion.h3 
                        className="text-green-700 dark:text-green-200 font-semibold text-xl sm:text-2xl"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5 }}
                      >
                        {t('riskDisplay.trusted.title')}
                      </motion.h3>
                      <motion.p 
                        className="text-green-600 dark:text-green-300 mt-1 text-sm sm:text-base"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.7 }}
                      >
                        {t('riskDisplay.trusted.description')}
                      </motion.p>
                    </div>
                  </div>
                </motion.div>

                {t('riskDisplay.trusted.checks', { returnObjects: true }).map((item, index) => (
                  <motion.div
                    key={index}
                    className="bg-gray-50/50 dark:bg-gray-900/30 rounded-xl p-3 sm:p-4"
                    initial={{ opacity: 0, x: -20, y: 20 }}
                    animate={{ opacity: 1, x: 0, y: 0 }}
                    transition={{ 
                      delay: 1.0 + (index * 0.5),
                      duration: 0.5,
                      type: "spring",
                      stiffness: 100 
                    }}
                  >
                    <div className="flex items-center space-x-3 sm:space-x-4">
                      <motion.div
                        className="flex items-center justify-center w-6 h-6 rounded-full bg-green-100 dark:bg-green-900/50"
                        initial={{ scale: 0, rotate: -180 }}
                        animate={{ scale: 1, rotate: 0 }}
                        transition={{ delay: 1.0 + (index * 0.5) + 0.2, type: "spring" }}
                      >
                        <CheckIcon className="w-4 h-4 text-green-600 dark:text-green-400" />
                      </motion.div>
                      <div className="flex-1">
                        <motion.h5 
                          className="text-gray-900 dark:text-white font-medium text-sm sm:text-base"
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 1.0 + (index * 0.5) + 0.3 }}
                        >
                          {item}
                        </motion.h5>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="fraud"
              className="h-full flex items-start pt-4 pb-0 sm:pt-6 sm:pb-4"
            >
              <div className="space-y-3 sm:space-y-4 p-4 sm:p-6 w-full">
                <motion.div 
                  className="bg-red-50 dark:bg-red-900/30 p-3 sm:p-4 rounded-xl border border-red-100 dark:border-red-800"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <div className="flex items-center space-x-3 sm:space-x-4">
                    <motion.div
                      className="w-3 h-3 sm:w-3.5 sm:h-3.5 rounded-full bg-red-500 aspect-square flex-shrink-0"
                      animate={{ 
                        scale: [1, 1.4, 1],
                        boxShadow: [
                          '0 0 0 0 rgba(239, 68, 68, 0.4)',
                          '0 0 0 10px rgba(239, 68, 68, 0)',
                          '0 0 0 0 rgba(239, 68, 68, 0)'
                        ]
                      }}
                      transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <div>
                      <motion.h3 
                        className="text-red-700 dark:text-red-200 font-semibold text-xl sm:text-2xl"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5 }}
                      >
                        {t('riskDisplay.fraud.title')}
                      </motion.h3>
                      <motion.p 
                        className="text-red-600 dark:text-red-300 mt-1 text-sm sm:text-base"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.7 }}
                      >
                        {t('riskDisplay.fraud.description')}
                      </motion.p>
                    </div>
                  </div>
                </motion.div>

                {t('riskDisplay.fraud.warnings', { returnObjects: true }).map((item, index) => (
                  <motion.div
                    key={index}
                    className="bg-gray-50/50 dark:bg-gray-900/30 rounded-xl p-3 sm:p-4"
                    initial={{ opacity: 0, x: -20, y: 20 }}
                    animate={{ opacity: 1, x: 0, y: 0 }}
                    transition={{ 
                      delay: 1.0 + (index * 0.5),
                      duration: 0.5,
                      type: "spring",
                      stiffness: 100 
                    }}
                  >
                    <div className="flex items-center space-x-3 sm:space-x-4">
                      <motion.div
                        className="flex items-center justify-center w-6 h-6 rounded-full bg-red-100 dark:bg-red-900/50"
                        initial={{ scale: 0, rotate: -180 }}
                        animate={{ scale: 1, rotate: 0 }}
                        transition={{ delay: 1.0 + (index * 0.5) + 0.2, type: "spring" }}
                      >
                        <svg className="w-4 h-4 text-red-600 dark:text-red-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v9m0 4v.01" />
                        </svg>
                      </motion.div>
                      <div className="flex-1">
                        <motion.h5 
                          className="text-gray-900 dark:text-white font-medium text-sm sm:text-base"
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 1.0 + (index * 0.5) + 0.3 }}
                        >
                          {item}
                        </motion.h5>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default RiskDisplay; 