/**
 * Layout Component
 * 
 * This is the main layout wrapper component used across the entire application.
 * It provides the following features:
 * - Responsive navigation header with mobile menu support
 * - Dark/light theme toggle functionality
 * - Sticky navigation with scroll-based transparency
 * - Footer with company information and links
 * - Automatic scroll handling for pricing section
 * 
 * This component wraps around all pages through the app's routing system,
 * ensuring consistent navigation and footer across all routes.
 */

import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from 'next-themes';
import { Menu, Transition } from '@headlessui/react';
import ScrollToTop from './ScrollToTop';
import { 
  SunIcon, 
  MoonIcon,
  Bars3Icon
} from '@heroicons/react/24/outline';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Layout({ children }) {
  const { t } = useTranslation(['common', 'layout']);
  const { resolvedTheme, setTheme } = useTheme();
  const [scrollY, setScrollY] = useState(0);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePricingClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      // If we're not on homepage, first navigate to home
      navigate('/', { state: { scrollTo: 'pricing' } });
    } else {
      // If we're already on homepage, just scroll
      document.getElementById('pricing')?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  if (!mounted) {
    return null;
  }

  const getNavStyles = () => {
    const scrollProgress = Math.min(scrollY / 100, 1);
    const opacity = scrollProgress * 0.8;
    const blur = Math.floor(scrollProgress * 12);

    return {
      backgroundColor: resolvedTheme === 'dark' 
        ? `rgb(17 24 39 / ${opacity})`
        : `rgb(255 255 255 / ${opacity})`,
      backdropFilter: `blur(${blur}px)`,
      WebkitBackdropFilter: `blur(${blur}px)`, // For Safari support
    };
  };

  return (
    <>
      {/* Navigation - now outside the main wrapper */}
      <ScrollToTop />
      <nav 
        className="fixed top-0 left-0 right-0 z-50 transition-all duration-200"
        style={getNavStyles()}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/">
                <img 
                  src={resolvedTheme === 'dark' ? '/logo_white.png' : '/logo_black.png'} 
                  alt="VerifyPDF Logo"
                  className="h-8 w-auto"
                />
              </Link>
            </div>
            
            {/* Right side navigation items */}
            <div className="flex items-center">
              {/* Desktop Navigation */}
              <div className="hidden lg:flex space-x-8 mr-8">
                <a 
                  href="/#pricing" 
                  className="text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                  onClick={handlePricingClick}
                >
                  {t('layout:navigation.pricing')}
                </a>
                <Link to="/blog" className="text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
                  {t('layout:navigation.blog')}
                </Link>
                <Link to="/contact" className="text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
                  {t('layout:navigation.contact')}
                </Link>
              </div>
              
              {/* Mobile Navigation + Theme + Language + Login */}
              <div className="flex items-center space-x-4 sm:space-x-6">
                {/* Language Switcher - mobile only */}
                <div className="lg:hidden">
                  <LanguageSwitcher />
                </div>

                {/* Theme Toggle - mobile position */}
                <div className="lg:hidden">
                  <button
                    onClick={() => setTheme(resolvedTheme === 'dark' ? 'light' : 'dark')}
                    className="p-2 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                  >
                    {resolvedTheme === 'dark' ? (
                      <SunIcon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
                    ) : (
                      <MoonIcon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
                    )}
                  </button>
                </div>

                {/* Mobile Navigation */}
                <div className="flex lg:hidden">
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button className="p-2 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors">
                      <Bars3Icon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
                    </Menu.Button>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-in"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/#pricing"
                                onClick={handlePricingClick}
                                className={classNames(
                                  active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                {t('layout:navigation.pricing')}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/blog"
                                className={classNames(
                                  active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                {t('layout:navigation.blog')}
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/contact"
                                className={classNames(
                                  active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white' : 'text-gray-700 dark:text-gray-300',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                {t('layout:navigation.contact')}
                              </Link>
                            )}
                          </Menu.Item>
                          <div className="border-t border-gray-200 dark:border-gray-700 my-1"></div>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="https://secure.verifypdf.com/"
                                className={classNames(
                                  'block px-4 py-2 text-sm text-white bg-primary-500 hover:bg-primary-400',
                                  'mx-2 my-1 rounded-md text-center font-semibold'
                                )}
                              >
                                {t('layout:navigation.login')}
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>

                {/* Desktop-only elements */}
                <div className="hidden lg:flex items-center space-x-6">
                  <LanguageSwitcher />
                  <button
                    onClick={() => setTheme(resolvedTheme === 'dark' ? 'light' : 'dark')}
                    className="p-2 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                  >
                    {resolvedTheme === 'dark' ? (
                      <SunIcon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
                    ) : (
                      <MoonIcon className="h-5 w-5 text-gray-600 dark:text-gray-400" />
                    )}
                  </button>
                  <a
                    href="https://secure.verifypdf.com/"
                    className="inline-flex items-center px-4 py-2 rounded-md bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    {t('layout:navigation.login')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Main content - no padding top */}
      <main>
        {children}
      </main>

      {/* Footer */}
      <footer className="bg-white dark:bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="hidden md:block col-span-1 md:col-span-2">
              <img 
                src={resolvedTheme === 'dark' ? '/logo_white.png' : '/logo_black.png'} 
                alt="VerifyPDF Logo"
                className="h-8 w-auto"
              />
              <p className="font-['Nunito'] mt-4 text-gray-600 dark:text-gray-300 max-w-md">
                {t('layout:footer.description')}
              </p>
              <div className="mt-6 flex space-x-6">
                <a 
                  href="https://www.linkedin.com/company/verifypdf/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                >
                  <span className="sr-only">LinkedIn</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                  </svg>
                </a>
              </div>
            </div>
            
            <div className="text-center md:text-left">
              <h3 className="font-['Domine'] text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider">
                {t('layout:footer.company.title')}
              </h3>
              <ul className="font-['Nunito'] mt-4 space-y-3">
                {[
                  { name: t('layout:footer.company.about'), href: '/about', badge: false },
                  { name: t('layout:footer.company.security'), href: '/security', badge: false },
                  { name: t('layout:footer.company.jobs'), href: '/jobs', badge: true },
                  { name: t('layout:footer.company.training'), href: '/training', badge: false }
                ].map((item) => (
                  <li key={item.name} className="flex items-center justify-center md:justify-start gap-x-2">
                    <Link to={item.href} className="text-base text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
                      {item.name}
                    </Link>
                    {item.badge && (
                      <span className="inline-flex items-center rounded-md bg-green-50 dark:bg-green-900/30 px-2 py-1 text-xs font-medium text-green-700 dark:text-green-400 ring-1 ring-inset ring-green-600/20 dark:ring-green-500/30">
                        {t('layout:footer.company.hiring')}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="text-center md:text-left">
              <h3 className="font-['Domine'] text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider">
                {t('layout:footer.support.title')}
              </h3>
              <ul className="font-['Nunito'] mt-4 space-y-3">
                {[
                  { name: t('layout:footer.support.apiStatus'), href: '/api-status' },
                  { name: t('layout:footer.support.terms'), href: '/terms' },
                  { name: t('layout:footer.support.privacy'), href: '/privacy' }
                ].map((item) => (
                  <li key={item.name}>
                    <Link to={item.href} className="text-base text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
          <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
            <p className="font-['Nunito'] text-base text-gray-400 text-center">
            © {new Date().getFullYear()} {t('layout:footer.copyright', { year: new Date().getFullYear() })}
            </p>
          </div>
        </div>
      </footer>
    </>
  );
} 