import { motion } from 'framer-motion';
import PageTemplate from '../components/PageTemplate';
import { 
  ShieldCheckIcon, 
  ServerIcon, 
  KeyIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SecurityFeature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center h-full p-6 pt-10 rounded-2xl bg-gradient-to-br from-blue-500/5 to-blue-500/10 dark:from-blue-500/5 dark:to-blue-500/10 backdrop-blur-sm border border-blue-500/10 dark:border-blue-400/10">
    <div className="mb-4 rounded-xl bg-blue-100 dark:bg-blue-900/30 p-4">
      <Icon className="h-8 w-8 text-blue-600 dark:text-blue-400" aria-hidden="true" />
    </div>
    <h3 className="font-['Nunito'] font-semibold text-gray-900 dark:text-white text-xl mb-3 text-center">
      {title}
    </h3>
    <p className="font-['Nunito'] text-base text-gray-600 dark:text-gray-300 leading-relaxed text-center">
      {description}
    </p>
  </div>
);

const Security = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <div className="min-h-screen bg-white dark:bg-gray-900">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative pt-6 pb-12 px-4 sm:px-6 lg:px-8 overflow-hidden"
        >
          <div className="relative z-10 max-w-7xl mx-auto text-center">
            <motion.h1 
              className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 mt-4 sm:mt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {t('security:hero.title')} <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700 animate-gradient font-black">
                {t('security:hero.highlight')}
              </span>
            </motion.h1>

            <motion.p
              className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              {t('security:hero.description')}
            </motion.p>
          </div>
        </motion.section>

        {/* Key Security Features */}
        <section className="relative py-16 bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <SecurityFeature
                icon={ShieldCheckIcon}
                title={t('security:features.zeroTrust.title')}
                description={t('security:features.zeroTrust.description')}
              />
              <SecurityFeature
                icon={KeyIcon}
                title={t('security:features.accessControls.title')}
                description={t('security:features.accessControls.description')}
              />
              <SecurityFeature
                icon={ServerIcon}
                title={t('security:features.infrastructure.title')}
                description={t('security:features.infrastructure.description')}
              />
            </dl>
          </div>
        </section>

        {/* Corporate Security Section */}
        <section className="relative bg-gradient-to-b from-gray-50 to-white dark:from-gray-800/50 dark:to-gray-900 rounded-2xl p-8 my-16">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="font-['Domine'] text-3xl font-bold mb-8 text-gray-900 dark:text-white text-center">
              {t('security:corporate.title')}
            </h2>
            <div className="space-y-6 text-gray-600 dark:text-gray-300">
              <p className="font-['Nunito'] mt-6 text-pretty text-base leading-7 text-gray-600 dark:text-gray-300 text-center max-w-3xl mx-auto">
                {t('security:corporate.description')}
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
                {t('security:corporate.features', { returnObjects: true }).map((feature, index) => (
                  <div
                    key={index}
                    className="relative p-5 rounded-xl bg-gradient-to-br from-white to-blue-50 dark:from-gray-800 dark:to-gray-800/50 border border-blue-100 dark:border-blue-900/50 shadow-sm hover:shadow-md transition-all duration-300 backdrop-blur-sm group"
                  >
                    <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-blue-500/[0.02] to-blue-500/[0.08] dark:from-blue-400/[0.02] dark:to-blue-400/[0.08] opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    
                    <div className="relative flex items-start space-x-3">
                      <div className="flex-shrink-0 w-6 h-6 rounded-full bg-gradient-to-r from-green-500 to-emerald-400 dark:from-green-400 dark:to-emerald-300 flex items-center justify-center shadow-sm">
                        <svg
                          className="w-3.5 h-3.5 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-base font-medium text-gray-700 dark:text-gray-200">
                        {feature}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <p className="font-['Nunito'] mt-6 text-pretty text-base leading-7 text-gray-600 dark:text-gray-300 text-center">
                {t('security:corporate.disclaimer')}
              </p>
              
              <div className="mt-8 flex justify-center pb-16">
                <Link
                  to="/contact"
                  className="no-underline rounded-md bg-primary-500 text-white shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500 px-3.5 py-2.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  {t('security:corporate.contactButton')}
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageTemplate>
  );
};

export default Security; 