/**
 * ScrollToTop is a utility component that automatically scrolls the window
 * to the top whenever the route (pathname) changes in a React Router application.
 * 
 * This component should be placed near the root of your app, typically inside
 * your Router component, to ensure proper scroll behavior when navigating
 * between different routes. This avoids the issue that forces a user to scroll
 * back to the top of the page when navigating between different routes.
 * 
 * Usage example:
 * <Router>
 *   <ScrollToTop />
 *   <App />
 * </Router>
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;