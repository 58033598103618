import PageTemplate from '../components/PageTemplate';

const ApiStatus = () => {
  return (
    <PageTemplate
      title="API Status"
      description="Current status and performance metrics for the VerifyPDF API"
    >
      <div className="max-w-2xl mx-auto">
        <div className="p-8 bg-gradient-to-br from-green-50 to-green-100 dark:from-green-900/20 dark:to-green-900/30 rounded-2xl shadow-sm border border-green-100 dark:border-green-800 backdrop-blur-sm flex items-center justify-center space-x-4">
          <svg
            className="w-8 h-8 text-green-500 dark:text-green-400 flex-shrink-0"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          <h2 className="text-xl font-semibold text-green-700 dark:text-green-400">
            All Systems Operational
          </h2>
        </div>
      </div>
    </PageTemplate>
  );
};

export default ApiStatus; 