import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { useLocation, useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const languages = [
    { code: 'en', name: 'English', flag: '🇬🇧' },
    { code: 'es', name: 'Español', flag: '🇪🇸' },
    { code: 'fr', name: 'Français', flag: '🇫🇷' },
    { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
    { code: 'nl', name: 'Nederlands', flag: '🇳🇱' }
  ];

  const handleLanguageChange = (langCode) => {
    i18n.changeLanguage(langCode);
    
    // Only modify URL if we're on the home page
    if (location.pathname === '/' || languages.some(lang => `/${lang.code}` === location.pathname)) {
      // For English, navigate to root, for others navigate to /lang-code
      navigate(langCode === 'en' ? '/' : `/${langCode}`);
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="p-2 transition-opacity hover:opacity-80">
        <span className="text-2xl">
          {languages.find(lang => lang.code === i18n.language)?.flag}
        </span>
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {languages.map((lang) => (
            <Menu.Item key={lang.code}>
              {({ active }) => (
                <button
                  onClick={() => handleLanguageChange(lang.code)}
                  className={`${
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  } ${
                    i18n.language === lang.code ? 'text-primary-600 dark:text-primary-400' : 'text-gray-700 dark:text-gray-300'
                  } group flex w-full items-center px-4 py-3 text-base`}
                >
                  <span className="text-xl mr-3">{lang.flag}</span>
                  {lang.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default LanguageSwitcher;
