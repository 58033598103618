import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PageTemplate from '../components/PageTemplate';
import { Link } from 'react-router-dom';

const getTimeUntilDeadline = (deadlineDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const deadline = new Date(deadlineDate);
  deadline.setHours(0, 0, 0, 0);
  
  const diffTime = deadline - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  if (diffDays < 0) return 'expired';
  if (diffDays === 0) return 'today';
  if (diffDays === 1) return 'tomorrow';
  return `${diffDays} days left`;
};

const formatDate = (date) => {
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

const jobListings = [
  {
    title: 'Backend Developer',
    type: 'Remote',
    postedDate: new Date('2024-11-22'),
    shortDescription: 'Architect and develop robust backend solutions while ensuring scalability, security, and performance of our document verification platform.',
    fullDescription: `
      As a Backend Developer at VerifyPDF, you'll be at the heart of our document verification platform. You'll work on building scalable microservices, optimizing database performance, and integrating with our AI systems to revolutionize how documents are verified globally.

      Key Responsibilities:
      • Design and implement scalable API endpoints and microservices
      • Optimize database performance and data structures
      • Collaborate with the ML team to integrate verification algorithms
      • Implement robust security measures and compliance standards
      • Participate in code reviews and technical documentation
      • Lead technical architecture discussions and decision-making
      • Mentor junior developers and promote best practices
      • Monitor and optimize system performance
      • Implement automated testing and CI/CD pipelines

      Requirements:
      • 3+ years experience with Node.js/Python and REST APIs (FastAPI preferred)
      • Strong knowledge of database design and optimization (DynamoDB preferred)
      • Experience with AWS
      • Understanding of security best practices
      • Experience with Docker and microservices architecture
      • Familiarity with message queuing systems (RabbitMQ/Kafka)
      • Knowledge of OAuth2 and OpenID Connect
      • Experience with monitoring tools and logging systems
      • Strong problem-solving and analytical skills

      What We Offer:
      • Competitive base salary plus performance bonuses
      • Possibilities for remote work from anywhere in the World
      • Stock options
      • 26 days of paid vacation
      • Your own ThinkPad X1 Carbon laptop
      • Yearly team event in Amsterdam (the Netherlands)
    `
  },
  {
    title: 'Machine Learning Engineer',
    type: 'Remote',
    postedDate: new Date('2024-11-20'),
    shortDescription: 'Pioneer cutting-edge AI solutions for document verification, developing and implementing advanced machine learning algorithms.',
    fullDescription: `
      Join our ML team to push the boundaries of document verification technology. You'll work on developing and improving our core AI algorithms that power our verification platform.

      Key Responsibilities:
      • Develop and optimize ML models for document verification and fraud detection
      • Design and implement computer vision algorithms for document analysis and feature extraction
      • Build and maintain fraud detection systems using anomaly detection and pattern recognition
      • Research and implement state-of-the-art ML techniques for document processing
      • Collaborate with backend team for efficient model deployment and integration
      • Optimize model performance and inference speed
      • Create and maintain model documentation and technical specifications
      • Participate in research projects and publish findings
      • Mentor junior ML engineers and contribute to team growth
      • Monitor model performance and maintain quality metrics

      Requirements:
      • 3+ years experience in practical ML applications
      • Strong Python programming skills
      • Expert knowledge of ML frameworks (PyTorch/TensorFlow)
      • Understanding of MLOps practices and deployment workflows
      • Experience with model optimization and quantization
      • Familiarity with GPU acceleration and CUDA
      • Familiarity with the PDF format

      What We Offer:
      • Competitive base salary plus performance bonuses
      • Possibilities for remote work from anywhere in the World
      • Stock options
      • 26 days of paid vacation
      • Your own ThinkPad X1 Carbon laptop
      • Yearly team event in Amsterdam (the Netherlands)
    `
  },
  {
    title: 'Customer Success Manager',
    type: 'Remote',
    postedDate: new Date('2024-11-15'),
    shortDescription: 'Drive client satisfaction and growth by providing strategic guidance, ensuring successful platform adoption.',
    fullDescription: `
      As Customer Success Manager, you'll be the bridge between our clients and our platform, ensuring they achieve maximum value from our solutions while driving growth and retention.

      Key Responsibilities:
      • Own and manage relationships with enterprise clients throughout their lifecycle
      • Develop and execute customer success strategies and adoption plans
      • Monitor and improve customer satisfaction metrics and NPS scores
      • Create and maintain customer health scorecards
      • Coordinate with technical teams to resolve client issues and implement solutions
      • Create and maintain customer education materials and documentation
      • Conduct regular business reviews with key accounts
      • Identify and pursue expansion opportunities within existing accounts
      • Manage renewal processes and reduce churn risk
      • Gather and communicate customer feedback to product teams
      • Develop best practices and success playbooks
      • Track and report on key customer success metrics
      • Organize and lead customer training sessions
      • Participate in customer advocacy programs

      Requirements:
      • 3+ years in customer success, preferably in SaaS/B2B
      • Strong communication and presentation skills
      • Experience with enterprise CRM systems (Pipedrive preferred)
      • Proven track record of managing enterprise relationships
      • Technical aptitude to understand our platform deeply
      • Strong project management and organizational skills
      • Data-driven approach to decision making
      • Ability to work independently in a remote environment

      What We Offer:
      • Competitive base salary plus performance bonuses
      • Possibilities for remote work from anywhere in the World
      • Stock options
      • 26 days of paid vacation
      • Your own ThinkPad X1 Carbon laptop
      • Yearly team event in Amsterdam (the Netherlands)
    `
  },
  {
    title: 'Senior Digital Marketeer',
    type: 'Remote',
    postedDate: new Date('2024-11-10'),
    shortDescription: 'Spearhead our digital marketing initiatives, focusing on SEO, Google Ads and Social Media. Increase brand awareness to bring quality leads.',
    fullDescription: `
      Lead our digital marketing efforts to expand VerifyPDF's market presence and drive growth through various digital channels. You'll be responsible for creating and executing comprehensive marketing strategies that align with our business objectives.

      Key Responsibilities:
      • Develop and execute comprehensive digital marketing strategies
      • Create and manage content marketing calendar and initiatives
      • Lead SEO optimization efforts and monitor performance
      • Manage paid advertising campaigns across multiple platforms
      • Create compelling marketing materials and sales collateral
      • Coordinate with sales team on lead generation and nurturing
      • Manage and grow our social media presence
      • Develop and maintain marketing automation workflows
      • Track and analyze marketing metrics and KPIs
      • Manage marketing budget and ROI tracking
      • Organize and participate in industry events
      • Create case studies and success stories
      • Maintain brand consistency across all channels
      • Collaborate with product team on launch strategies
      • Conduct market research and competitor analysis
      • Manage relationships with marketing vendors and agencies

      Requirements:
      • 5+ years digital marketing experience in B2B/SaaS
      • Strong analytics and data-driven mindset
      • Proven experience with SEO, SEM and content marketing
      • Advanced proficiency in marketing automation tools
      • Experience with marketing analytics platforms
      • Strong writing and editing skills

      What We Offer:
      • Competitive base salary plus performance bonuses
      • Possibilities for remote work from anywhere in the World
      • Stock options
      • 26 days of paid vacation
      • Your own ThinkPad X1 Carbon laptop
      • Yearly team event in Amsterdam (the Netherlands)
    `
  },
  {
    title: 'Account Manager (German Speaking)',
    type: 'Remote',
    postedDate: new Date('2024-11-01'),
    shortDescription: 'Lead our expansion in German-speaking markets by building and nurturing key client relationships and ensuring exceptional client satisfaction.',
    fullDescription: `
      Join our European team to manage and expand our presence in German-speaking markets, working directly with key clients and partners. You'll be responsible for driving growth and maintaining strong relationships in the DACH region.

      Key Responsibilities:
      • Manage and grow relationships with German-speaking enterprise clients
      • Drive account growth and expansion opportunities
      • Develop and execute account strategies and business plans
      • Coordinate with technical teams for client solution implementation
      • Conduct product demonstrations and training sessions
      • Report on market trends and growth opportunities
      • Manage contract negotiations and renewal processes
      • Create and maintain account plans and forecasts
      • Collaborate with marketing on regional initiatives
      • Represent VerifyPDF at industry events and conferences
      • Monitor competitive landscape in DACH region
      • Maintain detailed account documentation
      • Ensure high client satisfaction and retention
      • Build relationships with key stakeholders
      • Provide market feedback to product and leadership teams

      Requirements:
      • Native/fluent German speaker with excellent English
      • 3+ years account management experience in B2B software
      • Strong understanding of enterprise software sales
      • Excellent presentation and negotiation skills
      • Experience with enterprise procurement processes
      • Knowledge of DACH market and business culture

      What We Offer:
      • Competitive base salary plus performance bonuses
      • Generous budget for single desk in a co-working space (e.g. WeWork)
      • Stock options
      • 26 days of paid vacation
      • Your own ThinkPad X1 Carbon laptop
      • Yearly team event in Amsterdam (the Netherlands)
    `
  },
].map(job => ({
  ...job,
  deadlineDate: new Date(job.postedDate.getTime() + (30 * 24 * 60 * 60 * 1000))
})).sort((a, b) => b.postedDate - a.postedDate);

const Jobs = () => {
  const [expandedJob, setExpandedJob] = useState(null);

  return (
    <PageTemplate>
      <div className="space-y-16">
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative pt-6 pb-12 px-4 sm:px-6 lg:px-8 overflow-hidden isolate"
        >


          <div className="relative z-10 max-w-7xl mx-auto text-center">
            <motion.h1 
              className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Join our mission to make a <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700">
                fraud-free world
              </span>
            </motion.h1>

            <motion.p
              className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              At VerifyPDF, we're building the future of document verification through cutting-edge AI technology. 
              Join our team of innovators and help make the digital world more secure and trustworthy.
            </motion.p>
          </div>
        </motion.section>

        {/* Jobs Section */}
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className="font-['Domine'] text-3xl font-bold text-gray-900 dark:text-white mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            Open vacancies ({jobListings.length})
          </motion.h2>

          <motion.div 
            className="space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            {jobListings.map((job, index) => (
              <motion.button 
                key={index}
                onClick={() => setExpandedJob(expandedJob === index ? null : index)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index }}
                className="group relative w-full text-left"
              >
                <div className="bg-white/60 dark:bg-gray-800/60 backdrop-blur-sm rounded-2xl shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700 transition-all duration-300 hover:shadow-xl">
                  <div className="p-6">
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
                      <h3 className="font-['Domine'] text-xl sm:text-2xl font-bold text-gray-900 dark:text-white group-hover:text-primary-600 dark:group-hover:text-primary-400 transition-colors">
                        {job.title}
                      </h3>
                      <span className="self-start px-4 py-1.5 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 rounded-full text-sm font-medium whitespace-nowrap">
                        {job.type}
                      </span>
                    </div>
                    <p className="text-base text-gray-600 dark:text-gray-300 mt-3 mb-4">
                      {job.shortDescription}
                    </p>
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center text-sm text-gray-500 dark:text-gray-400 gap-2">
                      <div className="flex flex-col space-y-1">
                        <span>Posted: {formatDate(job.postedDate)}</span>
                        <span>
                          Deadline: {formatDate(job.deadlineDate)}
                          {getTimeUntilDeadline(job.deadlineDate) !== 'expired' && (
                            <span className="text-gray-500 dark:text-gray-400 ml-1">
                              ({getTimeUntilDeadline(job.deadlineDate)})
                            </span>
                          )}
                        </span>
                      </div>
                      <button 
                        className="text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 font-medium"
                      >
                        {expandedJob === index ? 'Show less ↑' : 'Learn more ↓'}
                      </button>
                    </div>
                  </div>

                  <AnimatePresence>
                    {expandedJob === index && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="px-8 pb-8 border-t border-gray-200 dark:border-gray-700"
                      >
                        <div className="prose dark:prose-invert max-w-none pt-6">
                          {job.fullDescription.split('\n').map((section, i) => {
                            const trimmedSection = section.trim();
                            if (!trimmedSection) return null;
                            
                            if (trimmedSection.includes('Key Responsibilities:') || 
                                trimmedSection.includes('Requirements:') || 
                                trimmedSection.includes('What We Offer:')) {
                              let header = trimmedSection;
                              if (header.includes('Key Responsibilities:')) {
                                header = 'Key responsibilities:';
                              } else if (header.includes('What We Offer:')) {
                                header = 'What we offer:';
                              }
                              return <h4 key={i} className="font-semibold text-lg mt-6 mb-4">{header}</h4>;
                            }
                            
                            if (trimmedSection.startsWith('•')) {
                              return (
                                <div key={i} className="flex items-start space-x-3 mt-2.5">
                                  <span className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600" />
                                  <span className="text-base leading-relaxed text-gray-600 dark:text-gray-300">
                                    {trimmedSection.substring(1).trim()}
                                  </span>
                                </div>
                              );
                            }
                            
                            return (
                              <p key={i} className="text-base leading-relaxed text-gray-600 dark:text-gray-300 mb-4">
                                {trimmedSection}
                              </p>
                            );
                          })}
                        </div>
                        {getTimeUntilDeadline(job.deadlineDate) === 'expired' ? (
                          <button
                            disabled
                            className="mt-8 px-4 py-2 rounded-md bg-gray-400 text-white text-sm font-semibold cursor-not-allowed opacity-60 no-underline"
                          >
                            Application window closed
                          </button>
                        ) : (
                          <Link
                            to="/contact"
                            className="inline-flex items-center mt-8 px-4 py-2 rounded-md bg-primary-500 text-white text-sm font-semibold shadow-sm hover:bg-primary-400 focus-visible:outline-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 no-underline hover:no-underline"
                          >
                            Apply now
                          </Link>
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.button>
            ))}
          </motion.div>
        </section>
      </div>
    </PageTemplate>
  );
};

export default Jobs; 