import { useEffect, useState } from 'react';
import PageTemplate from '../components/PageTemplate';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Demo = () => {
  const { t } = useTranslation('demo');
  const [scriptStatus, setScriptStatus] = useState('loading');

  useEffect(() => {
    const src = 'https://js.storylane.io/js/v2/storylane.js';
    
    // Check if custom elements are already defined
    if (customElements.get('sl-close-button')) {
      setScriptStatus('ready');
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      document.body.appendChild(script);
    }

    const handleLoad = () => setScriptStatus('ready');
    const handleError = () => setScriptStatus('error');

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    return () => {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <PageTemplate>
      {/* Hero Section */}
      <motion.section 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative pt-6 pb-12 px-4 sm:px-6 lg:px-8 overflow-hidden"
      >
        <div className="relative z-10 max-w-7xl mx-auto text-center">
          <motion.h1 
            className="font-['Domine'] text-4xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 mt-4 sm:mt-8 px-4 md:px-12 lg:px-24"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            {t('hero.title')}{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700 animate-gradient">
              {t('hero.highlight')}
            </span>
          </motion.h1>

          <motion.p
            className="font-['Nunito'] mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            {t('hero.description')}
          </motion.p>
        </div>
      </motion.section>

      {/* Widget Section */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div
          className="sl-embed"
          style={{
            position: 'relative',
            paddingBottom: 'calc(68.51% + 25px)',
            width: '100%',
            height: 0,
            transform: 'scale(1)',
          }}
        >
          <iframe
            loading="lazy"
            className="sl-demo"
            src="https://app.storylane.io/demo/arzl8p2lgxqj?embed=inline"
            name="sl-embed"
            allow="fullscreen"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: '1px solid rgba(63,95,172,0.35)',
              boxShadow: '0px 0px 18px rgba(26, 19, 72, 0.15)',
              borderRadius: '10px',
              boxSizing: 'border-box',
            }}
          />
        </div>
      </div>
    </PageTemplate>
  );
};

export default Demo; 